import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main"
};
export function render(_ctx, _cache) {
  const _component_van_empty = _resolveComponent("van-empty");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_empty, {
    image: "network",
    description: "NOT FOUND"
  })]);
}